<template>
  <div class="wrapper">
    <div class="cancel">
      <router-link to="/">
        <CloseIcon />
      </router-link>
    </div>

    <div class="auth-banner">
      <img
        src="/img/chop-banner.webp"
        style="width: 100%; border-radius: 10px; max-width: 100%"
      />
    </div>
    <section class="register-form-wrapper p-4">
      <h3 class="text-center">{{ $t("forgotPassword") }}</h3>
      <div class="text-center mb-4">
        <p>
          {{ $t("enterRegisteredPhoneRest") }}
        </p>
      </div>
      <div class="form-wrapper">
        <TextInput type="number" v-model="msisdn" placeholder="Phone number" />
      </div>

      <ChopbetButton
        :loading="spinner"
        variant="Variant"
        @click="handleResetClick"
        ><span style="text-transform: uppercase">{{ $t("continue") }}</span>
      </ChopbetButton>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import TextInput from "../../../components/ui/TextInput.vue";
import { mapState } from "vuex";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import CloseIcon from "../components/CloseIcon.vue";

export default {
  name: "forgot-password",
  components: { TextInput, ChopbetButton, CloseIcon },
  comments: {},
  data: function () {
    return {
      msisdn: "",
      password: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      placeBet: 0,
      showSuccessModal: false,
    };
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralCode"]),
  },
  methods: {
    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError(`${this.$t("pleaseEnterValidMobile")}`);
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },

    trackSignupButtonClick(event) {
      pushToDataLayer("gtm.register", {
        category: "Button",
        action: "Click",
        label: "Register",
        element: event.target,
      });
    },

    handleResetClick(event) {
      this.getResetCode(event);
    },
    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },
    getResetCode: function (event) {
      this.reset();
      if (this.msisdn && this.msisdn.toString().length < 9) {
        this.setError(`${this.$t("pleaseEnterValidMobile")}`);
        return;
      }

      const numberValue = `225${this.msisdn}`;

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      var payload = {
        msisdn: parseInt(numberValue),
      };

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log(JSON.stringify(res));

          vm.trackResetButtonClick(event);

          this.setSuccess(`${this.$t("passwordResetCode")}`);

          vm.$router.push("/reset-password");
        })
        .catch((err) => {
          vm.spinner = false;
          console.log(JSON.stringify(err));

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },

    handleVerifyClick(event) {
      this.verifyAccount(event);
    },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "forgot-password");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
    if (!p) {
      console.log("");
    } else {
      this.$router.push({ name: "home", params: {} });
      return;
    }
  },
};
</script>

<style src="./index.css" scoped></style>
